/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import remark from "remark";
import remarkHTML from "remark-html";
import Content, { HTMLContent } from "../components/Content";
import FeaturedProducts from "../components/FeaturedProducts";
import FeaturedProjects from "../components/FeaturedProjects";
import ProductGallery from "../components/productGallery/ProductGallery";
import ColorMark from "../components/ColorMark";
import EmailLink from "../components/EmailLink";
import { useCart } from "../hooks/useCart";
import ExternalLink from "../components/ExternalLink";
import { useContext } from "react";
import { DropdownContext } from "../providers/DropdownProvider";

export const ProductPostTemplate = ({
  title,
  helmet,
  details,
  price,
  content,
  products,
  relatedproductstitle,
  relatedprojectstitle,
  imagegallery,
  outofstock,
  outofstockbuttontext,
  product,
  projects,
  contacttext,
  email,
}) => {
  const value = remark().use(remarkHTML).processSync(details).toString();
  const PostContent = HTMLContent || Content;
  const [colorKey, setColorKey] = useState(0);
  const [imageKey, setImagekey] = useState(0);
  const [color, setColor] = useState(imagegallery[0].productcolor);
  const { addProductToCart } = useCart();
  const { openDropdown, closeDropdown } = useContext(DropdownContext);

  function selectSlide(index, productColor) {
    setColorKey(index);
    setImagekey(index);
    setColor(productColor);
  }

  return (
    <Fragment>
      {helmet || ""}
      <section
        sx={{
          display: "grid",
          gridTemplateColumns: ["100vw", null, "repeat(2, 50vw)"],
          pt: ["0px", null, "75px"],
          mb: [5, 6],
        }}
      >
        <div>
          {imagegallery && imagegallery.length > 0 ? (
            <ProductGallery
              images={imagegallery}
              imageKey={imageKey}
              selectSlide={selectSlide}
              title={title}
            />
          ) : null}
        </div>
        <article
          sx={{
            px: [4, 5, 6],
            mb: [6, null, "0px"],
            mt: [5, null],
          }}
        >
          <h1 sx={{ fontSize: 6, display: ["none", null, "block"] }}>
            {title}
          </h1>
          {outofstock && contacttext && email && (
            <div
              sx={{ mt: 3, color: "error", a: { fontSize: 3, color: "error" } }}
            >
              <p sx={{ fontSize: 3 }}>{contacttext}</p>
              <ExternalLink url={`mailto:${email}`} color={"#333"}>
                {email}
              </ExternalLink>
            </div>
          )}
          <div sx={{ display: "flex", ml: "-8px" }}>
            {imagegallery.map((image, index) => {
              if (image.productcolor !== "" && image.productcolor !== null) {
                return (
                  <ColorMark
                    key={index}
                    hexcode={image.hexcode}
                    index={index}
                    handleclick={() => selectSlide(index, image.productcolor)}
                    colorKey={colorKey}
                  />
                );
              }
              return null;
            })}
          </div>
          <div sx={{ my: 4, p: { my: 3 } }}>
            <PostContent content={content} />
          </div>
          <div sx={{ my: 4, p: { my: 3 } }}>
            <h5 sx={{ pt: 3, fontSize: 3 }}>Product Details:</h5>
            <PostContent content={value} />
          </div>
          <div sx={{ my: 4 }}>
            <h5 sx={{ pt: 3, fontSize: 3 }}>
              Color:
              <span sx={{ textTransform: "capitalize", ml: 2, fontSize: 1 }}>
                {color}
              </span>
            </h5>
          </div>
            <div>
              <EmailLink />
            </div>
        </article>
      </section>
      <div sx={{ mt: [7, null, 8], mx: [4, 7] }}>
        {projects && projects.length > 0 && (
          <FeaturedProjects projects={projects} title={relatedprojectstitle} />
        )}
      </div>
      <div sx={{ my: 8, mx: [4, 7] }}>
        {products && products.length > 0 && (
          <FeaturedProducts products={products} title={relatedproductstitle} />
        )}
      </div>
    </Fragment>
  );
};

ProductPostTemplate.propTypes = {
  title: PropTypes.string,
  helmet: PropTypes.object,
  details: PropTypes.string,
  price: PropTypes.string,
  content: PropTypes.node.isRequired,
  products: PropTypes.array,
  relatedproductstitle: PropTypes.string,
  relatedprojectstitle: PropTypes.string,
  imagegallery: PropTypes.array,
  outofstock: PropTypes.bool,
  outofstockbuttontext: PropTypes.string,
  product: PropTypes.object,
  projects: PropTypes.array,
  contacttext: PropTypes.string,
  email: PropTypes.string,
};

const ProductPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ProductPostTemplate
        content={post.html}
        details={post.frontmatter.details}
        price={post.frontmatter.price}
        projects={post.frontmatter.projects}
        outofstock={post.frontmatter.outofstock}
        outofstockbuttontext={post.frontmatter.outofstockbuttontext}
        products={post.frontmatter.products}
        imagegallery={post.frontmatter.imagegallery}
        title={post.frontmatter.title}
        relatedproductstitle={post.frontmatter.relatedproductstitle}
        relatedprojectstitle={post.frontmatter.relatedprojectstitle}
        contacttext={post.frontmatter.contacttext}
        email={post.frontmatter.email}
        product={post}
        helmet={
          <Helmet titleTemplate="%s | Product">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
      />
    </Layout>
  );
};

ProductPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ProductPost;

export const pageQuery = graphql`
  query ProductPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        details
        price
        details
        outofstock
        outofstockbuttontext
        products
        projects
        relatedproductstitle
        relatedprojectstitle
        contacttext
        email
        imagegallery {
          alttext
          hexcode
          productcolor
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
