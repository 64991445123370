/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import FeaturedProductsCarousel from "./FeaturedProductsCarousel";
import { findProducts } from "../utils/functions";

const FeaturedProducts = ({ products, title }) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ProductsQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "product-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                price
                outofstock
                imagegallery {
                  productcolor
                  hexcode
                  alttext
                  image {
                    childImageSharp {
                      fluid(maxWidth: 900, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const { edges } = allMarkdownRemark;
  const featuredProducts = findProducts(products, edges);

  const filteredFeaturedProducts = featuredProducts.filter(function (el) {
    return el != null;
  });

  return (
    <aside>
      <div>
        <h2
          sx={{
            textAlign: "center",
            fontSize: [5, null, 6],
            textTransform: "uppercase",
          }}
        >
          {title}
        </h2>
      </div>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: "center",
          div: {
            ":nth-of-type(4)": {
              display: ["block", "none", "block"],
            },
          },
        }}
      >
        {filteredFeaturedProducts &&
          filteredFeaturedProducts.map(({ node }) => {
            return (
              <div
                key={node.id}
                sx={{
                  maxWidth: ["100%", "25%"],
                  minWidth: ["100%", "25%"],
                  mx: [0, 3],
                }}
              >
                {node?.frontmatter?.imagegallery?.length > 0 && (
                  <FeaturedProductsCarousel
                    slug={node.fields.slug}
                    images={node.frontmatter.imagegallery}
                    title={node.frontmatter.title}
                    price={node.frontmatter.price}
                    disabled={node.frontmatter.outofstock}
                    product={node}
                  />
                )}
              </div>
            );
          })}
      </div>
    </aside>
  );
};

export default FeaturedProducts;

FeaturedProducts.propTypes = {
  products: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
