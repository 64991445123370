/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

const MainImage = ({ image }) => {
  return (
    <PreviewCompatibleImage
      imageInfo={{
        image: image,
      }}
    />
  );
};

export default MainImage;
