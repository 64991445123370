/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ColorMark from "./ColorMark";
import { Fragment } from "react";
import EmailLink from "./EmailLink";
import { useCart } from "../hooks/useCart";
import { useContext } from "react";
import { DropdownContext } from "../providers/DropdownProvider";

const FeaturedProductsCarousel = ({ product }) => {
  const slug = product.fields.slug;
  const images = product.frontmatter.imagegallery;
  const title = product.frontmatter.title;
  const price = product.frontmatter.price;
  const disabled = product.frontmatter.outofstock;

  const imgWuniqueColors = images.filter(
    (image) => image.productcolor !== "" && image.productcolor !== null
  );

  const [colorKey, setColorKey] = useState(0);
  const { addProductToCart } = useCart();
  const { closeDropdown, openDropdown } = useContext(DropdownContext);

  //set initial color to the first color in the image array
  const [color, setColor] = useState(imgWuniqueColors[0].productcolor);

  function selectSlide(index, color) {
    setColorKey(index);
    setColor(color);
    sliderRef.current.slickGoTo(index);
  }

  function selectColorOnSwipe(index, imgWuniqueColors) {
    if (index === 0) {
      let currentIndex = 1;
      setColorKey(currentIndex);
      setColor(imgWuniqueColors[currentIndex].productcolor);
    } else if (index + 1 === imgWuniqueColors.length) {
      let currentIndex = 0;
      setColorKey(currentIndex);
      setColor(imgWuniqueColors[currentIndex].productcolor);
    } else {
      let currentIndex = index + 1;
      setColorKey(currentIndex);
      setColor(imgWuniqueColors[currentIndex].productcolor);
    }
  }

  let sliderRef = useRef(null);
  const settings = {
    initialSlide: 0,
    swipeToSlide: true,
    slidesPerRow: 1,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Fragment>
      <div sx={{ py: 6 }}>
        <Slider ref={sliderRef} {...settings}>
          {imgWuniqueColors.map((image, index) => {
            if (image.productcolor !== null || image.productcolor !== "") {
              return (
                <div
                  key={`${image.productcolor}${index}`}
                  onTouchEnd={() => selectColorOnSwipe(index, imgWuniqueColors)}
                  sx={{
                    height: ["300px", "200px", "300px", "400px"],
                    div: {
                      height: "100%",
                    },
                  }}
                >
                  <Link to={slug}>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: image.image,
                      }}
                    />
                  </Link>
                </div>
              );
            }
            return null;
          })}
        </Slider>
        <div sx={{ textAlign: "center", mt: 3 }}>
          <h3 sx={{ textTransform: "capitalize", fontSize: 5, m: 0 }}>
            {title}
          </h3>
        </div>
        <div sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          {imgWuniqueColors.map((image, index) => {
            if (
              image?.productcolor !== "" &&
              image?.productcolor !== null &&
              image?.productcolor
            ) {
              return (
                <ColorMark
                  key={index}
                  hexcode={image.hexcode}
                  index={index}
                  handleclick={() => selectSlide(index, image.productcolor)}
                  colorKey={colorKey}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
      <div>
        <EmailLink />
      </div>
    </Fragment>
  );
};

export default FeaturedProductsCarousel;

FeaturedProductsCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
