/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment } from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MainImage from "../productGallery/MainImage";

const ProductGallery = ({ images, imageKey, selectSlide, title }) => {
  const settings = {
    initialSlide: 0,
    infinite: images.length > 4,
    swipeToSlide: true,
    slidesPerRow: 1,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: "left-align-slick",
  };

  return (
    <Fragment>
      <div
        sx={{
          height: ["85vh", null, "90vh"],
          position: "relative",
          div: { height: "100%" },
        }}
      >
        <MainImage image={images[imageKey].image} />
        <h1
          sx={{
            position: "absolute",
            zIndex: 2,
            fontFamily: "body",
            textTransform: "uppercase",
            fontSize: 5,
            bottom: "50px",
            pl: 3,
            color: "white",
            display: ["block", null, "none"],
          }}
        >
          {title}
        </h1>
      </div>
      <Slider {...settings}>
        {images.map((image, index) => {
          return (
            <div
              key={`${image.productcolor}${index}`}
              onClick={() => selectSlide(index, image.productcolor)}
              sx={{
                height: ["130px", "200px"],
                marginX: "4px",
                div: {
                  height: "100%",
                  width: "calc(100% - 8px)",
                },
              }}
            >
              <PreviewCompatibleImage
                key={`${image.productcolor}${index}`}
                imageInfo={{
                  image: image.image,
                }}
              />
            </div>
          );
        })}
      </Slider>
    </Fragment>
  );
};

export default ProductGallery;

ProductGallery.propTypes = {
  images: PropTypes.array.isRequired,
  selectSlide: PropTypes.func.isRequired,
};
