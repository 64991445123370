/** @jsx jsx */
import { jsx } from "theme-ui";

const EmailLink = () => {
  return (
      <a
        href="mailto:info@hahastudio.com"
        sx={{
          display: "block",
          textAlign: "center",
          pointer: "cursor",
          textDecoration: "none",
          border: "1px solid",
          borderColor: "black",
          color: "black",
          paddingX: 3,
          paddingY: "12px",
          width: "100%",
          textTransform: "uppercase",
          fontSize: 0,
          backgroundColor: "white",
          outline: "none",
          ":active, :hover, :focus": {
            color: "white",
            backgroundColor: "black",
          },
        }}
      >
        Get a quote
      </a>
  );
};
export default EmailLink;
