/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const ColorMark = ({ hexcode, index, handleclick, colorKey, color }) => {
  return (
    <div
      onClick={() => handleclick(index, color)}
      sx={{
        mt: 2,
        cursor: "pointer",
        marginX: 2,
        height: ["26px", null, "34px"],
        width: ["26px", null, "34px"],
        borderRadius: "50%",
        border: colorKey === index ? "1px solid" : "none",
        borderColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        sx={{
          height: ["20px", null, "28px"],
          width: ["20px", null, "28px"],
          borderRadius: "50%",
          backgroundColor: hexcode,
        }}
      />
    </div>
  );
};
export default ColorMark;

ColorMark.propTypes = {
  hexcode: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleclick: PropTypes.func.isRequired,
  colorKey: PropTypes.number.isRequired,
};
