/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import { findProducts } from "../utils/functions";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { kebabCase } from "lodash";
import SectionTitle from "./SectionTitle";

const FeaturedProjects = ({ projects, title }) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ProjectQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "project-post" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                featuredimagealt
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    dots: true,
    focusOnSelect: true,
    className: "image-carousel-slick",
  };

  const { edges } = allMarkdownRemark;
  const featuredProjects = findProducts(projects, edges);

  return (
    <aside>
      <div sx={{ mb: [4, null, 5] }}>
        <SectionTitle title={title} />
      </div>
      {featuredProjects.length > 0 ? (
        <Slider {...settings}>
          {featuredProjects.map(({ node }) => {
            return (
              <div key={node.id}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: node.frontmatter.featuredimage,
                  }}
                />
                <Link to={`/projects/${kebabCase(node.frontmatter.title)}/`}>
                  <p
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      fontFamily: "body",
                      color: "white",
                      fontSize: 5,
                      bottom: "12px",
                      textTransform: "uppercase",
                      pl: 3,
                    }}
                  >
                    {node.frontmatter.title}
                  </p>
                </Link>
              </div>
            );
          })}
        </Slider>
      ) : null}
    </aside>
  );
};

export default FeaturedProjects;

FeaturedProjects.propTypes = {
  projects: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
